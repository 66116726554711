/* eslint-disable vue/no-duplicate-attributes */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item mt-2">
							<!-- <div class="settings__avatar"> -->
							<img v-if="itemData.logo" :src="itemData.logo" alt="Stock Logo" id="stockLogo" />
							<img v-else src="../../assets/img/profile.png" alt="stock avatar" />
						</div>
						<div class="user-profile__item mt-2">
							<p class="user-profile__section__title">Name:</p>
							<p id="stockName">{{ itemData.name }}</p>
						</div>
						<div class="user-profile__item mt-2">
							<p class="user-profile__section__title">Symbol:</p>
							<p id="stockSymbol">{{ itemData.symbol }}</p>
						</div>
						<div class="user-profile__item mt-2">
							<p class="user-profile__section__title">Sector:</p>
							<p id="stockSector">{{ itemData.sector }}</p>
						</div>
						<div class="user-profile__item mt-2">
							<p class="user-profile__section__title">Status:</p>
							<span :class="status" id="stockStatus">
								{{ itemData.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE' }}
							</span>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Detail
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Name</label>
													<input
														v-model="itemData.name"
														name="name"
														type="text"
														class="form-control"
														id="stockName"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Sector</label>
													<input
														v-model="itemData.sector"
														name="sector"
														type="text"
														class="form-control"
														id="stockSector"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="type">Type</label>
													<input
														v-model="itemData.type"
														type="text"
														name="type"
														class="form-control"
														id="stockType"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Exchange</label>
													<input
														v-model="itemData.exchange"
														name="exchange"
														type="text"
														class="form-control"
														id="stockExchange"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Market</label>
													<input
														v-model="itemData.market"
														name="market"
														type="text"
														class="form-control"
														id="stockMarket"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Order Size Step:</label>
													<input
														v-model="itemData.order_size_step"
														name="sector"
														type="text"
														class="form-control"
														id="stockOrderSizeStep"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Order Size Max:</label>
													<input
														v-model="itemData.order_size_max"
														type="text"
														class="form-control"
														id="stockOrderSizeMax"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Order Size Min:</label>
													<input
														v-model="itemData.order_size_step"
														type="text"
														class="form-control"
														id="stockOrderSizeMin"
														disabled
													/>
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="description">Description</label>
													<p id="description">{{ itemData.description }}</p>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';

export default {
	name: 'StockDetail',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchStockDetail(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getStockDetail;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getStockDetail', 'getErrorLog']),

		status() {
			return [
				this.itemData.status === 'ACTIVE' ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'
			];
		}
	},
	methods: {
		...mapActions(['fetchStockDetail'])
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.activity__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-gap: 25px;
	margin-bottom: 22px;
}
.settings {
	max-width: 90%;
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}

.avatar {
	&__input {
		display: none;
	}
	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		&:hover {
			cursor: pointer;
		}
	}
	&__loader {
		position: absolute;
		top: 7px;
		right: -45px;
	}
}
h3 {
	padding-left: 6px;
}
.card {
	border-style: none;
}
.doc {
	&__wrapper {
		.text {
			margin-bottom: 15px;
		}
		margin-bottom: 50px;
	}
	&__img {
		width: 215px;
		height: 150px;
		object-fit: fill;
		object-position: center;
		//border: 1px solid $grey-light;
		border-radius: 4px;
		&--lg {
			width: 100%;
			height: auto;
		}
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		margin: 4px;
		padding: 4px;
	}
}
.bold {
	font-weight: bold;
	font-size: 15px;
	margin-right: 5px;
}

.cards {
	padding-top: 2em;
	margin-right: 3em;
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__brand {
		font-weight: bold;
		padding-top: 1rem;
	}
	&__item {
		font-size: 1.5em;
		background: #f1f1f1;
		border: 1px solid #cccccc;
		border-radius: 6px;
		padding: 0.875em 1em;
		max-width: 350px;
		margin-bottom: 2.25rem;
		&:hover {
			.btn-sm {
				visibility: visible;
			}
		}
	}
}
.pad {
	padding: 10px;
}
</style>
